import { createTheme, responsiveFontSizes } from "@mui/material/styles";


const tribalismTheme = createTheme({
  palette: {
    common: {
      white: '#fff'
    }
  },
  components: {
    MuiSelect: {
      defaultProps: {
        variant: "standard",
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#fff !important",
          },
        },
      },
    },
  },
})

export default responsiveFontSizes(tribalismTheme);